/*eslint-disable*/ import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Parallax from "components/Parallax/Parallax.js"

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js"

const useStyles = makeStyles(landingPageStyle)

const ParallaxSection = ({image, children, filter, className, containerClass}) => {
  const classes = useStyles()

  return (
    <Parallax image={image} filter={filter} className={className}>
      <div className={containerClass}>
        {children}
      </div>
    </Parallax>
  )
}

export default ParallaxSection
